<template>
  <div>
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />
      <div onmousedown="return false" onselectstart="return false">
        <section style="margin-top: 10rem">
          <div class="row">
            <div class="col-sm-12">
              <h5 class="ml-lg-5 text-dark font-style">Plans List</h5>
            </div>
          </div>
          <div class="row m-lg-5 text-center shadow rounded p-lg-3">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Plan Name</th>
                  <th scope="col">Plan Period</th>
                  <th scope="col">Remaining Credits</th>
                  <th scope="col">Used Credits</th>
                  <th scope="col">Alloted Date</th>
                  <th scope="col">Plan Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(list, index) in plansdetails" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td v-if="list.status != 1">
                    {{ list.plan_name }}
                  </td>
                  <td v-if="list.status != 0">
                    <CLink :to="'/plandata/' + list.plans_id" class="href">{{
                      list.plan_name
                    }}</CLink>
                  </td>
                  <td v-if="list.plan_name == 'Trial'">
                    Free For {{ list.plan_period }} {{ list.plan_period_type }}
                  </td>
                  <td v-else>
                    {{ list.plan_period }} {{ list.plan_period_type }}
                  </td>
                  <td>
                    {{ list.creadited_points - list.used_points }}
                  </td><td>
                    {{ list.used_points }}
                  </td>
                  <td>{{ list.created_at | formatDate }}</td>
                  <td v-if="list.status != 0" class="active123">Active</td>
                  <td v-if="list.status != 1" class="deactive">Deactive</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
    </CWrapper>
  </div>
</template>
<script>
import TheSidebar from "../../containers/TheSidebar";
import TheHeader from "../../containers/TheHeader";
import TheFooter from "../../containers/TheFooter";
import moment from "moment";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      plansdetails: [],
      userdetect: {
        user_id12: null,
      },
    };
  },
  name: "Dashboard",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    moment,
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.getUserplan();
      // this.demo();
    }
  },
  methods: {
    ...mapActions(["getUserplanlist"]),
    getUserplan() {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.token = localStorage.getItem("token");
      this.getUserplanlist(this.userdetect).then((res) => {
        this.plansdetails = res.data.data;
      });
    },
  },
};
</script>
<style scoped>
.href {
  color: #1e38a7;
  text-decoration-line: none;
}

.href:hover {
  border-bottom: white 1px solid;
  color: #fa2b56;
}
.active123 {
  color: green;
  font-weight: 600;
  background-color: white !important;
}
.deactive {
  color: red;
  font-weight: 600;
}
</style>
